<template>
    <div class="storeList">
        <div class="storeItem" v-for="(item,index) in dataArr" :key="index">
            <div class="title"><span class="tip1">门店名：</span>{{item.storeName}}</div>

            <div class="title" v-if="[1,3].indexOf(item.status) != -1"><span class="tip1 tip2">审核状态：</span><span
                    class="tip3 tip4">{{ getStatusStr(item) }}</span>
            </div>
            <div class="flex-row title title1" v-if="[5,6].indexOf(item.status) != -1"><span
                    class="tip1 tip2">是否展示门店：</span><span class="tip3">{{ item.status == 5?'展示':'隐藏' }}</span>
                <div class="updateBtn" @click="updateStateBtnClick(item)">
                    {{item.status==5?'隐藏':'展示'}}</div>

            </div>
            <div class="flex-row address"><span class="tip1 tip2">门店地址：</span><span class="tip3">{{item.address}}</span>
            </div>
            <div class="line1"></div>
            <div v-if="item.type" class="flex-row btn-box">
                <div class="editBtn" @click="editInfo(item)">编辑</div>

                <div class="editBtn" @click="couponList(item)">套餐</div>

                <div class="editBtn" @click="personList(item)">核销人</div>
            </div>
            <div v-else class="flex-row addBtn" @click="addInfo(item.storeId)">
                添加门店展示信息
            </div>


        </div>

    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return {
            dataArr:[],
            quarmas: {
                pageNum: 1,
                pageSize: 10,
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            total: 0,
            copy: false,
            // 是否为预分成商户
            isAdvance: false
        };
    },
    created() { 
    },
    mounted() {
        this.init();
    },
    methods: {
        addpage() {
            this.copy = true;
            this.quarmas.pageNum = this.quarmas.pageNum + 1;
            this.init();
        },
        init() {
            console.log('获取门店列表')
            this.$http.getNewStoreList(this.quarmas).then((res) => {
                if (res.code == 200) {
                    this.dataArr = res.data
                }
            });
        },

        getStatusStr(item) {
            if (item.status == 1) {
                return '待审核'
            }
            if (item.status == 2) {
                return '审核通过'
            }
            if (item.status == 3) {
                return '审核拒绝'
            }
            if (item.status == 5) {
                return '展示门店'
            }
            if (item.status == 6) {
                return '不展示门店'
            }
        },

        addInfo(storeId) {
            this.$router.push('/storeEdit?storeId='+storeId)

        },
        editInfo(item) {
            this.$router.push('/storeEdit?isEdit=true&storeId=' + item.storeId+'&Id='+item.id)
            
        },
        couponList(item) {
            this.$router.push('/storeCouponList?&Id=' + item.id)
        },
        personList(item) {
            this.$router.push('/storePersonList?&adcId=' + item.id)
        },

        updateStateBtnClick(item) {
            var param = {
                storeId: item.storeId,
                adcId: item.id,
                id: item.id,
                status:item.status == 5?6:5
            }
            this.$http.updateAdcStoreStatus(param).then(res => {
                if (res.code == 200) {
                    Toast.success('变更成功')
                    item.status = param.status
                }
                
            })
            
        }


    },
};
</script>
<style lang="scss" scoped>
.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.storeList {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #f8f8f8;
    .storeItem {
        margin: 0 auto;
        margin-top: 24px;
        width: 335px;
        background: white;
        border-radius: 8px;
        padding: 15px;
        box-sizing: border-box;
        .tip1{
            font-size: 14px;
            color: rgba(33, 33, 33, 1);
            line-height: 20px;
            font-weight: 450;
        }

        .title{
            margin-top: 5px;
            font-size: 14px;
            line-height: 20px;
        }
        .title1{
            align-items: center;
            .updateBtn {
                width: 60px;
                height: 25px;
                border-radius: 5px;
                background-color: rgba(21, 123, 250, 1);
                margin-left: 10px;
                line-height: 25px;
                color: white;
                font-size: 10px;
                text-align: center;
            }
            .tip4 {
                color: red;
            }
        }
        .address{
            margin-top: 5px;
            font-size: 13px;
            line-height: 20px;
            color: rgba(153,153,153,1);
            .tip3{
                flex: 1;
                min-width: 0;
            }
        }

        .line1{
            width: 100%;
            height: 1px;
            background: #f8f8f8;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .addBtn{
            height: 25px;
            justify-content: center;
            align-items: center;
            line-height: 25px;
            font-size: 14px;
        
        }

        .btn-box{
            align-items: center;
            justify-content: center;
            font-size: 13px;
            .editBtn {
                width: 30%;
                text-align: center;
            }
            
        }




        
    }
}
</style>